import "./hours-table.scss";

import { CtaLink } from "design-system/components/primitives/cta-link/cta-link";
import { Theme } from "design-system/utils/theme";

export interface HoursTableItem {
  heading: string;
  hours: string;
  locations: Array<string>;
}

export interface HoursTableProps {
  hoursTitle: string;
  currentDate: string;
  allHoursTitle?: string;
  allHoursTitleUrl?: string;
  theme?: Theme;
  hourItems: Array<HoursTableItem>;
}

export function HoursTable({
  hoursTitle,
  currentDate,
  hourItems,
  allHoursTitle,
  allHoursTitleUrl,
  theme = "crimson",
}: HoursTableProps) {
  return (
    <div className="hbs-global-align-full hbs-hours-table" data-theme={theme}>
      <div className="hbs-hours-table__session">
        <div className="hbs-hours-table-header">
          <div className="hbs-hours-table-header__text">
            <h2 className="hbs-hours-table-header__title hbs-text-h5">
              {hoursTitle}
            </h2>
            <p className="hbs-hours-table__subtitle">{currentDate}</p>
          </div>
          {allHoursTitle && (
            <CtaLink href={allHoursTitleUrl} isSmall>
              {allHoursTitle}
            </CtaLink>
          )}
        </div>
        <ul className="hbs-hours-table__info-items">
          {hourItems.map((item, i) => (
            <li key={i} className="hbs-hours-table__info-item">
              <span className="hbs-hours-table__info-header">
                <p className="hbs-hours-table__info-heading">{item.heading}</p>
                <p className="hbs-hours-table__info-hours">{item.hours}</p>
              </span>
              <div className="hbs-hours-table__info-locations">
                {item.locations.map((location, i) => {
                  const isLast = i === item.locations.length - 1;
                  return (
                    <p key={i}>
                      {location}
                      {!isLast && ","}
                    </p>
                  );
                })}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
