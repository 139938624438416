import {
  HoursTable,
  HoursTableItem,
} from "design-system/sites/baker/hours-table/hours-table";
import { HoursTableEntry } from "frontend/contentful/schema/blocks";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { useContentful } from "frontend/hooks/use-contentful";
import { useFetch } from "frontend/hooks/use-fetch";

interface LibraryAPIResponse {
  result: Array<HoursTableData>;
}

interface HoursTableData {
  name: string;
  category: string;
  dates: Record<
    string,
    {
      status: string;
      hours?: Array<{
        from: string;
        to: string;
      }>;
    }
  >;
}

export const ContentfulHoursTable = createContentfulComponent<HoursTableEntry>(
  ({ entry }) => {
    const { getEntry, getEntryUrl } = useContentful();
    const link = getEntry(entry.fields?.link);

    if (!entry.fields.title) {
      return;
    }

    const { data } = useFetch<LibraryAPIResponse>({
      url: "/api/hours/library",
    });

    let date = "";
    const hoursItems: Array<HoursTableItem> = [];

    function splitLocation(location: string) {
      const regex = /^(.*) \((.*)\)$/;
      const match = location.match(regex);

      if (match) {
        const name = match[1];
        const room = match[2];

        return [name, room];
      }

      return [location, ""];
    }

    function formatTime(start: string, end: string) {
      const formattedStart = start.replace(":00", "");
      const formattedEnd = end.replace(":00", "");
      return `${formattedStart}—${formattedEnd}`;
    }

    function formatDate(dateString: string) {
      const date = new Date(`${dateString}T00:00:00`);
      return date.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        timeZone: "America/New_York",
      });
    }

    if (data?.result?.length) {
      const departments = data.result.filter(
        (d: HoursTableData) => d.category === "department",
      );

      departments.forEach((dept) => {
        let item = {};
        Object.entries(dept.dates).forEach(([key, value]) => {
          date = formatDate(key);
          const hoursObj = value?.hours?.length ? value.hours[0] : null;
          const [heading, location] = splitLocation(dept.name);
          let hours = "Closed";

          if (value?.status !== "Closed" && hoursObj) {
            hours = formatTime(hoursObj.from, hoursObj.to);
          }

          item = {
            heading,
            hours,
            locations: [location],
          };
        });

        if (item) {
          hoursItems.push(item as HoursTableItem);
        }
      });
    }

    return (
      <HoursTable
        hoursTitle={entry.fields.title}
        currentDate={date}
        hourItems={hoursItems}
        allHoursTitle={link?.fields.text}
        allHoursTitleUrl={getEntryUrl(link)}
        theme={entry.fields.theme}
      />
    );
  },
);
