export const PREVIEW_PARAM = "preview";
export const HBS_DOMAIN_PARAM = "domain";
export const CONTENTFUL_ENV_PARAM = "environment";

export const ES_QUERY_ENDPOINT = "https://www.hbs.edu/api/contentful/search";
export const NAVIGATION_PAGE_TYPES = [
  "landingPage",
  "detailPage",
  "archivePage",
]; // only these page types appear in navigation (breadcrumbs, side-nav)
export const CONTENTFUL_PAGE_TYPES = [
  ...NAVIGATION_PAGE_TYPES,
  "articlePage",
  "programPage",
  "profilePage",
  "participantStory",
  "knowledgeBaseArticlePage",
  "databasePage",
  "datasetPage",
  "podcastEpisodePage",
];

export const GOOGLE_TAG_IDS = {
  DEFAULT: "GTM-N2NWNWX",
  ONLINE: "GTM-MW34RML",
  EXED: "GTM-TLS4CQL",
  MY: "GTM-WNLL79Z",
};
